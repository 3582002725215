<template>
    <!-- 确认收货弹窗 -->
    <transition name="fade">
        <div class="receiving" v-show="isShow">
            <div class="bag" @click="fadeRece()"></div>
            <div class="f_cont">
                <div class="tit" v-html="title"></div>
                <div class="but">
                    <a href="javascript:;" @click="fadeRece()" class="hm-but f_but">
                        <span>取消</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </a>
                    <a href="javascript:;" @click="queRece()" class="hm-but">
                        <span>确认</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </a>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props:["title",'isShow'],
    
    data(){
        return {
           
        }
    },
    mounted(){

    },
    methods:{
        fadeRece(){  //取消弹窗
            this.$emit("altOut");
        },
        queRece(){
            this.$emit("besure");
        }
    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>