<template>
  <div class="order1">
    <div class="weeb_load basefont40"  v-if="error==0">加载中....</div>
    <template v-else-if="error==1">
        <div class="f_tit basefont30">
            订单中心
        </div>
        <div class="nav">
            <swiper :options="order_nav" class="order_nav" ref="mySwiper">
                <swiper-slide v-for="(item, index) in orderNav" :class="{on:cout==index}" :key="index">
                    <a href="javascript:;" @click="toggleOrder(index,item.id)" class="item">{{item.title}}</a>
                </swiper-slide>
            </swiper>
        </div>
        <div class="f_jut">
            <form class="form" @submit.prevent="shoppSearch()">
                <input type="text" v-model="search" placeholder="请输入商品名称">
                <button type="submit"  class="f_sub"><img src="../../assets/images/wsearchiconimgf1.png" alt=""></button>
            </form>
        </div>
        <div v-show="ordershu.length>0?true:false">
            <div class="cont">
                <div class="item tit">
                    <!-- <div class="name check"><i></i><span>选择</span></div> -->
                    <div class="name prmes">产品信息</div>
                    <div class="name shu">数量</div>
                    <div class="name zhuang">订单状态</div>
                    <div class="name ser">选择服务</div>
                </div>
                <div class="item" v-for="(item,index) in ordershu" :key="index">
                    <!-- <div :class="{name: true,check: true,on:item.check1}" @click="xuanzhong(index)">
                        <i v-if="item.orderStatus==1"></i>
                    </div> -->
                    <div class="name prmes">
                        <i class="img"><img :src="item.orderItemInfoList[0].skuDefaultImg" :alt="item.orderItemInfoList[0].skuName"></i>
                        <div class="font">
                            <div class="fw_tit">
                                {{item.orderItemInfoList[0].skuName}}
                            </div>
                            <div class="text" v-html="item.text"></div>
                        </div>
                    </div>
                    <div class="name shu"><div class="zi">数量：</div>{{item.totalBuyNum}}</div>
                    <div class="name zhuang"><div class="zi">订单状态：</div>
                        {{statusData(item.orderStatus)}}
                    </div>
                    <div class="name ser">
                        <!-- <a href="javascript:;" @click="jumpInfo(item)" class="f_but"><span>查看订单详情</span></a>
                        <a href="javascript:;" @click="proaltShow()" class="f_but"><span>扫码去APP查看</span></a> -->
                        <template v-if="item.orderStatus==4"> <!-- 待签收 -->
                            <a href="javascript:;" @click="receiving(item.orderNo)" class="f_but"><span>确认收货</span></a>
                             <a href="javascript:;" @click="sales(item.orderNo)"  class="f_but"><span>申请退货</span></a>
                        </template>
                        <template v-if="item.orderStatus==2 || item.orderStatus==3 || item.orderStatus==4"> <!-- 待发货 已发货 待签收 -->
                            <a href="javascript:;" @click="viewLogistics(item)" class="f_but"><span>查看物流</span></a>
                            <a href="javascript:;" @click="invoice(item.orderNo)" class="f_but"><span>申请开票</span></a>
                            <a href="javascript:;" @click="sales(item)"  class="f_but"><span>申请退货</span></a>
                        </template>
                        <template v-if="item.orderStatus==1"> <!-- 待支付 -->
                            <a href="javascript:;" @click="plyto(item)" class="f_but"><span>去支付</span></a>
                        </template>
                        <template v-if="item.orderStatus==6 || item.orderStatus==5">  <!-- 待退款和已退款 -->
                            <a href="javascript:;" @click="returnSchedule(item.orderNo)" class="f_but"><span>退货进度</span></a>
                        </template>
                        <template v-if="item.orderStatus==7">   <!-- 已取消 -->
                            <!-- <a href="javascript:;" class="f_but"><span>去支付</span></a> -->
                            <!-- <a href="javascript:;" class="f_but"><span>删除订单</span></a> -->
                        </template>
                        <template v-if="item.orderStatus!=8">
                            <a href="javascript:;" @click="orderInfo(item)" class="f_but"><span>订单详情</span></a>
                        </template>
                        <template v-if="item.orderStatus==8">  <!-- 已完成 -->
                            <a href="javascript:;"  @click="sales(item)" class="f_but"><span>申请售后</span></a>
                        </template>
                    </div>
                </div>
            </div>
            <el-pagination
            hide-on-single-page
            layout="prev, pager, next"
            :page-size= limit
            :current-page = page 
            @current-change="curpage"
            :total= total
            class="basepage">
            </el-pagination>
            <div class="fw_jut" style="display: none">
                <div class="lt">
                    <div :class="{check: true,on:qucheck}" @click="checkQuan()">
                        <i></i>
                        <span>待支付订单全选</span>
                    </div>
                    <!-- <a href="javascript:;" @click="removeSh1()" class="f_but">删除选中的商品</a> -->
                </div>
                <div class="gt">
                    <div class="shu">已选择 <span>{{lengthShop}}</span> 件订单</div>
                    <div class="jia">总价：<strong>¥ {{zongjia}}</strong></div>
                    <a href="javascript:;"  @click="proaltShow()" class="hm-but f_but">
                        <span>去结算</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </a>
                </div>
            </div>
        </div>
        <div class="weeb_load basefont40" v-show="ordershu.length<=0?true:false" style="margin-top: 0.6rem">您没有相关订单</div>
        
        
        <!-- 售后弹窗 -->
        <div class="aftersale">
            <div class="bag" @click="afteFadeOut()"></div>
            <div class="f_cont">
                <a href="javascript:;" @click="afteFadeOut()" class="close" ><img src="../../assets/images/closeiconf1.png" alt=""></a>
                <div class="tit basefont30">售后申请</div>
                <div class="text">
                    提交售后申请后，我们会在24小时内向您致电，了解订单情况，请保持电话通畅
                </div>
                <form action="" class="form">
                    <div class="item">
                        <div class="name">售后类型：</div>
                        <div class="fz_jut">
                            <label><input type="radio" name="lei" v-model="saleform.lei" value="货物损伤"><i></i><span>货物损伤</span></label>
                            <label><input type="radio" name="lei" v-model="saleform.lei" value="退/换货"><i></i><span>退/换货</span></label>
                            <label><input type="radio" name="lei" v-model="saleform.lei" value="其他"><i></i><span>其他</span></label>
                        </div>
                    </div>
                    <div class="item">
                        <div class="name">提交原因：</div>
                        <div class="fz_jut">
                            <textarea name="" v-model="saleform.yin"></textarea>
                        </div>
                    </div>
                    <button type="submi" @click.prevent="saleSubmit()" class="hm-but">
                        <span>提交申请</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </button>
                </form>
                <div class="f_text">
                    我们提供的售后服务仅限符合条件的客户，恶意订单我们有权拒绝客户的售后
                    要求。详细信息，请联系XXXX售后服务中心。
                </div>
            </div>
        </div>
        <!-- 退货弹窗 -->
        <message-popup
            @altOut="altOut"
            @besure="confirtake"
            :title="msgTit"
            :isShow="isShow">
        </message-popup>
    </template>
    <div class="weeb_load basefont40" v-else>您还没有订单</div>
    <!-- <div class="fzjiedian">
        <div class="tit basefont24">
            去APP支付
        </div>
        <div class="text">您的订单尚未支付，请尽快到APP进行支付哦。</div>
        <div class="img">
            <img :src="erimgfz" alt="">
        </div>
    </div> -->
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import { mapState } from 'vuex';

import * as math from "mathjs";
import messagePopup from '@/components/pooup/message-popup.vue';
export default {
  name: "Myorder",
  components: {
    swiper,
    swiperSlide,
    messagePopup
  },
  data(){
    return {
        order_nav: {
            // loop: true,
            observer:true,
            observeParents:true,
            observeSlideChildren:true,
            slidesPerView: "auto",
            watchOverflow:true,
            // autoplay: {
            // delay: 4000,
            // stopOnLastSlide: false,
            // disableOnInteraction: false,
            // },
        },
        msgTit: '',
        cout: 0,
        orderNav: [
            {
                title: "全部订单",
                id: 0,
            },
            {
                title: "待付款",
                id: 1,
            },
            {
                title: "待发货",
                id: 2,
            },
            {
                title: "待收货",
                id: 4,
            },
            {
                title: "退换/售后",
                id: 6,
            }
        ],
        ordershu:[],
        qucheck: false, //全选
        search: "",  //搜索结果
        saleform:{
            lei:"",
            yin: ""
        },
        page: 1, //页数
        limit: 4, //条数
        total: 0,
        error: 0,
        isShow: false,
        confirOrder: {}
    }
  },
  mounted(){
    this.getProductList();
  },
  computed:{ //computed 计算属性
      zongjia:function(){  //计算总价格
         var zong = 0;
        for(var i=0;i<this.ordershu.length;i++){
            if(this.ordershu[i].check1==true){
                zong=math.evaluate(zong+" + ("+this.ordershu[i].totalBuyNum+" * "+this.ordershu[i].goodsPrice+")");
            }		
        }
        zong;
        return zong;
      },
      lengthShop:function(){ //计算订单个数
          let i = 0; //选中的长度
          this.ordershu.find((atr1)=>{
              if(atr1.check1==true){
                  i++;
              }
          });
          return i;
      },
      ...mapState(['erimgfz'])
  },
  methods: {
    getLocalStorageData(name){
        let data = localStorage.getItem(name);
        return data;
    },
    setLocalStorageData(name,obj){
        let data = typeof obj == 'string'?obj:JSON.stringify(obj);
        localStorage.setItem(name,data);
    },
    invoice(id){
        this.$router.push({name: "Invoice", params:{
            id: id
        }});
    },
    sales(obj){ //申请退货
        this.setLocalStorageData("orderMessage",JSON.stringify(obj));
        this.$router.push({name: "Sales"});
    },
     plyto(obj){ //去支付
        this.setLocalStorageData("orderMessage",JSON.stringify(obj));
        this.$router.push({name: "Payment"});
    },
    returnSchedule(id){ //退货进度
        this.$router.push({name: "Salesprogress",params:{
            id: id
        }});
    },
    proaltShow(){
        $(".proinfoAlt").fadeIn();
    },
    statusData(_orid){
        let str = "";
        if(_orid==1){
            str="待支付"
        }
        if(_orid==2){
            str="已支付"
        }
        if(_orid==3){
            str="已发货"
        }
        if(_orid==4){
            str="待签收"
        }
        if(_orid==5){
            str="待退款"
        }
        if(_orid==6){
            str="已退款"
        }
        if(_orid==7){
            str="已取消"
        }
        if(_orid==8){
            str="交易完成"
        }
        return str;
    },
    payment:function(){
        console.log(this.zongjia);
        if(this.zongjia==0){
            this.$message({
                message: '请选择订单',
                type: 'warning',
            });
            return false;
        }else{
            this.$router.push({path: "/confirmorder",query: {id: "1"}})
    }
    },
    afteFadeIn:function(){ //售后弹窗显示
        $(".aftersale").fadeIn();
    },
    afteFadeOut:function(){ //售后弹窗隐藏
        $(".aftersale").fadeOut();
    },
    saleSubmit:function(){ //售后弹窗提交
        console.log(this.saleform);
    },
    toggleOrder:function(index,_id){  //nav导航选中
        this.cout=index;
        this.page=1;
        this.qucheck=false; //全选取消
        this.$nextTick( () => {
            this.getProductList();
        });
    },
    shoppSearch:function(){ //产品搜索
        this.$axios.post("/api2/portal/auth/order/detail/searchByGoodsName",{
            orderStatus: this.orderNav[this.cout].id,  //查询的类型
            keyword: this.search, //搜索的关键字 
            current: this.page, //当前页
            size: this.limit, //搜索的条数
        }).then(res => {
            if(res.code=="000000"){
                this.error=1;
                this.total = res.data.total; //获取总条数
                let _data = res.data.data.map( it => {
                    // 订单详细介绍
                    it.text = `<p>
                        ${it.orderItemInfoList[0].skuDesc}
                    </p>
                    <p>
                        单价：¥ ${it.goodsPrice} 
                    </p>`;
                    // 订单选中状态
                    if(it.orderStatus==1){
                        it.check1=false;
                    }
                    return it;
                });
                this.ordershu = _data; //订单数据填充
            }else{
                this.error = 2;
            }
        }).catch( error => {
            console.log(error);
        });
    },
    xuanzhong:function(index){ //单选
        this.ordershu[index].check1=!this.ordershu[index].check1;
        let sel1 = 0; //选中的长度
        let diel = 0; //订单的长度
        this.ordershu.find((atr1)=> {
            if(atr1.check1==true && atr1.check1!=undefined){
                sel1++;
            }
            if(atr1.orderStatus==1){
                diel++;
            }
        });
        if(sel1==diel){
            this.qucheck=true;
        }else{
            this.qucheck=false;
        }
    },
    checkQuan:function(){  //全选
        if(this.qucheck){
            this.ordershu.find((atr1)=>{
            if(atr1.orderStatus==1){
                atr1.check1=false;
            }
        });
        }else{
            this.ordershu.find((atr1)=>{
                
                if(atr1.orderStatus==1){
                    atr1.check1=true;
                }
            });
        }
        this.qucheck=!this.qucheck;
    },
    removeSh1:function(){ //删除商品
    for(let i in this.ordershu){
        if(this.ordershu[i].ding==1 && this.ordershu[i].check1==true){
            this.ordershu.splice(i,1);
            this.removeSh1();
            
        }else{
            this.qucheck=false;
            return false;
        }
    }
    },
    viewLogistics:function(obj){ //查看物流
       this.setLocalStorageData("orderMessage",JSON.stringify(obj));
       this.$router.push({path: '/logistics'});
    },
    orderInfo(obj){ //订单详情
        this.setLocalStorageData("orderMessage",JSON.stringify(obj));
        this.$router.push({name: 'Orderdet'});
    },
    confirtake(){  //确认提交
        this.$axios.post('/api2/portal/auth/order/detail/confirmReceipt',{
            orderNo: this.confirOrder
       }).then( res => {
            console.log(res);
            if(res.code=="000000"){
                this.$message({
                    message: "收货成功！",
                    type: 'success',
                });
                this.page = 1;
                this.getProductList();  //更新数据
                this.altOut();  //隐藏弹窗
            }else{
                this.$message({
                    message: res.message,
                    type: 'error',
                });
            }
       });
    },
    receiving(obj){  //显示确认收货
       this.msgTit = `
            <p>为了保证您的售后权益</p>
            <p>请收到商品检查无误后再确认收货</p>
       `;
       this.confirOrder = obj;
       this.isShow = true;
    },
    getProductList(){ //订单页面数据获取
        this.$axios.post("/api2/portal/auth/order/detail/getMyOrderList",{
            orderStatus: this.orderNav[this.cout].id,
            current: this.page,  //当前页数
            size: this.limit,  //每页条数
        }).then( res => {
            if(res.code=="000000"){

                this.total = res.data.total; //获取总条数
                if(this.total!=0){
                    this.error=1;
                }else{
                    if(this.cout==0){
                        this.error=3;
                    }
                    
                }
                let _data = res.data.data.map( it => {
                    // 订单详细介绍
                    it.text = `<p>
                        ${it.orderItemInfoList[0].skuDesc}
                    </p>
                    <p>
                        单价：¥ ${it.goodsPrice} 
                    </p>`;
                    // 订单选中状态
                    if(it.orderStatus==1){
                        it.check1=false;
                    }
                    return it;
                });
                this.ordershu = _data; //订单数据填充
                
            }else{
                this.error=3;
            }
        }).catch(error => {
            console.log(error);
        });
    },
    curpage(val){
        this.page=val;
        this.qucheck=false; //全选取消
        this.$nextTick( () => {
            this.getProductList();
        });
    },
    altOut(){
        this.isShow = false;
        this.confirOrder = {};
    }
  }
}
</script>


<style lang="less" scoped>
    .order1 .cont .item .ser .f_but{
        
        margin-bottom: 12px;
        @media (min-width: 768px) {
            display: block;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    
</style>